import { useState } from 'react'
import { IoCheckmarkCircleOutline } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom'
import api from '../../../config/connection/axios'
import FormButton from '../../Form/Inputs/nested/FormButton'
import Input from '../../Form/Inputs/nested/Input'

import './styles.css'
import SafeDiv from '../../SafeDiv'
import headers from '../../../constants/localStorage/headers'


function ResetPasswordContainer({ token }: any) {
    const navigate = useNavigate()

    const [spinner, setSpinner] = useState<boolean>(false)
    const [password, setPassword] = useState<string>('')
    const [success, setSuccess] = useState<boolean>(false)

    const handleSubmit = async (e: any, alertfy: any) => {
        e.preventDefault()
        setSpinner(true)

        await api.put(
            '/users/updates/password',
            { password },
            {headers:headers()}
        )
            .then(r => {
                const result = r.data;
                setSuccess(true)
                setTimeout(() => {
                    navigate('/login')
                }, 3000)
            })
            .catch(() => {
                setSpinner(false)
                alertfy('Por favor tente novamente mais tarde')
            })
    }

    return (
        <SafeDiv>
            {
                success
                    ? <div style={{ width: '320px', height: '320px' }} className='center column'>
                        <span className='center'>
                            <IoCheckmarkCircleOutline
                                size={120}
                                color='#00AE74'
                            />
                        </span>
                        <span className='RPC-span' style={{ width: '100%', textAlign: 'center', marginTop: '40px' }}>Senha alterada com sucesso!</span>

                    </div>
                    : <form className='ResetPasswordContainer'>
                        <span className='RPC-span'>Redifinir Senha</span>
                        <p className='RPC-p'>crie uma nova senha com no mínimo 8 caracteres</p>
                        <Input
                            label='Nova Senha'
                            placeholder='••••••••'
                            type='password'
                            value={password}
                            onChange={setPassword}
                        />
                        <FormButton
                            space={6}
                            text='Alterar'
                            onClick={handleSubmit}
                            spinner={spinner}
                        />
                    </form>
            }
        </SafeDiv>
    )
}

export default ResetPasswordContainer
import { useEffect, useState } from 'react'
import api from '../../../../config/connection/axios';
import { maskPhone } from '../../../../functions/masks';
import TrashButton from '../../../Buttons/TrashButton';
import FormButton from '../../../Form/Inputs/nested/FormButton';
import Input from '../../../Form/Inputs/nested/Input';
import { TextArea } from '../../../Form/Inputs/nested/TextArea';


import RegCheck from './RegCheck';
import MaxLengthDesc from '../../../MaxLengthDesc';
import headers from '../../../../constants/localStorage/headers';

function RegCMyRegistration() {
    const [name, setName] = useState<string>('');
    const [profession, setProfession] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const [uid, setUid] = useState<string>('');
    const [countDesc, setCountDesc] = useState<number>(0);

    const [sent, setSent] = useState<boolean>(false)
    const [spinner, setSpinner] = useState<boolean>(false);

    useEffect(() => {
        const data = JSON.parse(window.localStorage.getItem('serss-user') || '{}')
        setName(data.name)
        setDescription(data.description)
        data.description && setCountDesc(data.description.length)
        setProfession(data.profession)
        setUid('@' + data.uid)
        data.phone && setPhone(maskPhone(data.phone))
    }, [])

    const handleSubmit = async (e: any, alertfy: any) => {
        e.preventDefault();

        if (typeof name !== 'string') return alertfy('Nome deve ter no mínimo 3 caracteres')
        if (name.trim().length < 3) return alertfy('Nome deve ter no mínimo 3 caracteres')
        if (description && description.trim().length > 510) return alertfy('Descrição deve ter no máximo 510 caracteres')

        setSpinner(true)

        const u = JSON.parse(window.localStorage.getItem('serss-user') || '{}')

        await api.put(
            '/users/update',
            {
                name: name.trim(),
                phone: phone.replace(/\D/g, ''),
                profession,
                description
            },
            {headers:headers()}
        )
            .then(() => {
                window.localStorage.setItem('serss-user', JSON.stringify({
                    ...u,
                    name,
                    phone: phone.replace(/\D/g, ''),
                    profession,
                    description
                }))
                setSent(true)
                setSpinner(false)
            })
            .catch(() => {
                setSpinner(false)
                alertfy('Por favor tente novamente mais tarde')
            })

    }

    const handleDescription = (value: string) => {
        setDescription(value);
        setCountDesc(value.length)
    }

    return (
        <form>
            <Input
                label='Identificador público'
                value={uid}
                onChange={() => { }}
                style={{ color: 'dodgerblue', fontWeight: 'bolder', backgroundColor: '#eee' }}
            />
            <Input
                label='Nome Completo'
                placeholder='Seu nome e sobrenome ou razão social'
                value={name}
                onChange={setName}

            />
            <Input
                label='Profissão'
                placeholder='Ex: Psicólogo'
                value={profession}
                onChange={setProfession}

            />
            <TextArea
                label='Sobre mim'
                placeholder='conte-nos sobre você e seu trabalho.'
                value={description}
                onChange={handleDescription}
            />
            <MaxLengthDesc countDesc={countDesc} />
            <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                <Input
                    label='Telefone'
                    placeholder='Exemplo: (11) 9 9988-7766'
                    value={phone}
                    onChange={setPhone}
                    mask='phone'
                />
                <TrashButton funk={(e) => { e.preventDefault(); setPhone('') }} />
            </div>
            <p style={{ margin: '6px 0 0 10px', fontSize: '.8em', color: '#00000060' }}>Ao informar seu número de contato você concorda em divulga-lo nos seus anúncios.</p>
            <div className='reg-default-buttons'>
                {
                    sent
                        ? <RegCheck />
                        : <FormButton
                            text='Salver alterações'
                            style={{
                                borderRadius: '100px',
                                backgroundColor: '#009b6730',
                                color: '#00ae74',
                                border: '0px'
                            }}
                            onClick={handleSubmit}
                            spinner={spinner}
                        />

                }
            </div>
        </form >
    )
}

export default RegCMyRegistration
import { useEffect, useState } from 'react'
import bannersStorage from '../../../constants/firebase/bannersStorage'
import './styles.css'

interface Banner_ {
    uid?: any,
    wid?: any,
    current?: string,
    type?: 'default' | 'main' | 'details',
    handleOnBannerError?: () => void,
    link?: string,

    idx?: undefined | number,
    hideWrapper?: any,

}
function Banner({ link = '', uid, wid, type = 'default', handleOnBannerError, idx, hideWrapper, current }: Banner_) {

    const [banner, setBanner] = useState<string>('')

    const handleBanner = () => {
        if (uid && wid)
            setBanner(bannersStorage.others(uid, wid, idx))
        else if (link)
            setBanner(link)
    }

    useEffect(() => {
        handleBanner()
    }, [uid, link]);

    return (
        <>
            <div
                className={`banners-image-wrap center ${type !== 'default' && 'banners-image-wrap-' + type}`}
            >

                {(banner.length) ?
                    <img
                    alt='banner do usuário'
                        className={`banners-img ${type !== 'default' && 'banners-img-' + type}`}
                        src={banner}
                    /> : <></>
                }
            </div>
            {(banner.length) ?
                <img
                alt='banner do usuário'
                    className='banners-blur'
                    src={banner}
                    onError={() => {
                        handleOnBannerError && handleOnBannerError()
                        setBanner('')
                        hideWrapper && hideWrapper()
                    }}
                /> : <></>
            }
        </>
    )
}

export default Banner;
import Input from '../../../Form/Inputs/nested/Input';
import { TextArea } from '../../../Form/Inputs/nested/TextArea';
import MaxLengthDesc from '../../../MaxLengthDesc';
import { useContext, useState } from 'react';
import CreateWorkContext from '../context';
import { IoTrashBinOutline } from 'react-icons/io5';
import WindowAlertContext from '../../../../context/windowAlert.context';
import shake from '../../../../languages/windowAlertForm.json';
import min from '../../../../languages/pt-BR/minPrice.json'
import WcTaxs from './WcTaxs';
import { subwork_ } from '../../DetailsContainer/interface';

const WcFirstModal = ({ setVisible, sw, increaseId, setIncreaseId }: { setVisible: any, sw?: subwork_, increaseId: number, setIncreaseId: (n: number) => void }) => {
    const { setSubworks } = useContext(CreateWorkContext);
    const windowAlert = useContext(WindowAlertContext);

    const [title, setTitle] = useState<string>(sw?.title || '');
    const [description, setDescription] = useState<string>(sw?.description || '');
    const [price, setPrice] = useState<number>(sw?.price || 0);

    const addEditX = () => {
        if (!title || !price)
            return windowAlert?.confirm({ shake })
        if (price < 50) return windowAlert?.confirm({
            shake: min
        })
        setIncreaseId(increaseId + 1);
        (sw && sw.id)
            ? setSubworks.edit({ id: sw.id, title, description, price })
            : setSubworks.push({ id: increaseId, title, description, price });
        setVisible();
    }

    const delX = () => {
        (sw && sw.id) && setSubworks.del(sw.id);
        setVisible();
    }

    return (
        <div className='wc-subworks-modal'>
            <Input
                label='Serviço'
                placeholder='Ex: Assentamento de piso'
                value={title}
                onChange={setTitle}
                required
            />
            <TextArea
                label='Descrição'
                placeholder='Ex: Colocação de revestimentos no chão, como cerâmica, porcelanato ou laminado, utilizando argamassa e ferramentas adequadas para garantir um acabamento uniforme e duradouro.'
                value={description}
                onChange={setDescription}
                
            />
            <MaxLengthDesc
                countDesc={
                    description
                        ? description.length
                        : 0
                }
            />
            <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between' }}>
                <div style={{ width: '48%', position: 'relative' }}>
                    <Input
                        label='Valor (R$)'
                        placeholder={min}
                        value={Boolean(price) && price}
                        onChange={setPrice}
                        type='number'
                        min={50}
                        clear={true}
                        required
                    />
                </div>
                <WcTaxs price={price} />
            </div>
            <div
                style={{ marginTop: '50px' }}
                className='center'
            >
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}
                >
                    {Boolean(sw && sw.id) && <button
                        type='button'
                        onClick={delX}
                        style={{ width: '70px' }}
                        className='center'
                    >
                        <IoTrashBinOutline size={22} />
                    </button>}
                    <button
                        type='button'
                        onClick={addEditX}
                        style={{
                            backgroundColor: 'var(--border)',
                            height: '48px',
                            flex: 1,
                            borderRadius: '8px'
                        }}
                    >
                        <strong>Confirmar</strong>
                    </button>
                </div>
            </div>
        </div>
    )
}


export default WcFirstModal;
import './styles.css'

import statesList from '../../constants/statesList.json'

import { useState, useEffect } from 'react'
import api from '../../config/connection/axios'
import Select from '../Form/Inputs/nested/Select'
import { setLocationData_ } from './interface'

interface IGetLocation {
    children?: any,
    setLocationData: setLocationData_,
    city_name: string | null,
    state_name: string | null,
    required?: boolean

}

function GetLocation({
    children,
    setLocationData,
    city_name,
    state_name,
    required
}: IGetLocation) {

    const [city, setCity] = useState<string>(city_name || '')
    const [stateName, setStateName] = useState<string>(state_name || 'Todo o Brasil')
    const [citiesList, setCitiesList] = useState<Array<object>>([{ name: 'Escolha primeiro o estado' }])
    const [dis, setDis] = useState<boolean>(stateName === 'Todo o Brasil' ? true : false)
    const [spinner, setSpinner] = useState<boolean>(false)


    const toggleStateName = async (value: string) => {
        if (value === 'Todo o Brasil') {
            setDis(true);
            setCitiesList([{ name: 'Escolha primeiro o estado' }])
        }
        setStateName(value)
        if (value === 'Todo o Brasil') setLocationData({
            state_name: value,
            state_id: null,
            city_name: null,
            city_id: null
        })
    }

    const handleOnChange = async () => {
        if (stateName !== 'Todo o Brasil') {

            setDis(true);
            setSpinner(true)

            const stt = statesList.find((sn) => sn['name'] === stateName)
            const state_id = stt !== undefined && stt['id']

            await api.get(`/list/cities/${state_id}`)
                .then(r => {
                    const result = r.data;
                    setCitiesList(result)
                    setCity(city || result[0].name)
                    returnLocationData(city || result[0].name, result)
                    setDis(false);
                    setSpinner(false)
                })
                .catch(() => {
                    setDis(false)
                    setSpinner(false)
                })
        } else if (stateName === 'Todo o Brasil') setLocationData({
            state_name: 'Todo o Brasil',
            state_id: null,
            city_name: null,
            city_id: null
        })
    }

    const handleCityChange = (e: any) => {
        setCity(e)
        returnLocationData(e.toString(), citiesList)
    }

    const returnLocationData = (cityName: string, list: Array<any>) => {

        const ct: any = list.find((c: any) => c['name'] === cityName)
        const locationData = {
            state_name: stateName && stateName,
            state_id: ct && parseInt(ct.id.toString().slice(0, 2)),
            city_name: ct && ct.name,
            city_id: ct && ct.id
        }
        setLocationData(locationData)
    }

    useEffect(() => {
        handleOnChange()
    }, [stateName])


    return (
        <div style={{ width: '100%' }}>
            <Select
                label='Estado'
                list={statesList.map((stateName) => stateName['name'])}
                value={stateName}
                onChange={toggleStateName}
            />
            <Select
                label='Cidade'
                list={citiesList.map((c: any) => c['name'])}
                value={city}
                onChange={handleCityChange}
                disabled={dis}
                spinner={spinner}
                required={required}
            />
            {children && children}
        </div>
    )
}

export default GetLocation
import './mp.styles.css';

function Mp() {
    return (
        <div
            className="mercad-pago-pgp"
        >
            <p style={{ fontSize: '12px' }}>Pagamento gerenciado pelo <MpIcon /> <strong style={{ color: '#00aeef' }}>Mercado Pago</strong></p>
        </div>
    )
}

const MpIcon = () =>
    <strong>
        <img
            style={{ height: '9px' }}
            src={require('../../../../../assets/mercado-pago.png')}
            alt='icone do mercado pago'
        />
    </strong>

export default Mp
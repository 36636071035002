import { useEffect } from 'react';

interface Pagination_ {
    title: string,
    children: JSX.Element
}

function Pagination({ title, children }: Pagination_) {

    useEffect(() => {
        document.title = title;
    }, [title]);

    return (<>{children}</>);
}

export default Pagination;
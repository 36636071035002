import Card from "../../../Cards/Card"
import works from '../../../../constants/homeWorks.json'

import './styles.css'

function HomeWorks({ box = 1 }: { box: 1 | 2 | 3 | 4 | 5 }) {
    return (
        <div className="HomeWorks">
            {
                works[box - 1].map((work, idx) => (
                    <Card
                        className="PreDefined"
                        work={work}
                        key={idx}

                    />
                ))
            }
        </div>
    )
}

export default HomeWorks
import { useState, useCallback } from 'react'
import Cropper from 'react-easy-crop'
import { IoClose } from 'react-icons/io5'
import FormButton from '../../Form/Inputs/nested/FormButton'

import getCroppedImg from './cropImage'
import './styles.css'


interface IEasyCrop {
  avatar: string,
  setVisible: (value: boolean) => void,
  handleSubmit: (value: any, alertfy: (text: string) => void) => Promise<void>,
  spinner: boolean
}
const EasyCrop = ({ avatar, setVisible, handleSubmit, spinner }: IEasyCrop) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [rotation, setRotation] = useState(0)
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)

  const onCropComplete = useCallback((croppedArea: any, croppedAreaPixels: any) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])


  const showCroppedImage = useCallback(async (alertfy: any) => {
    try {
      const croppedImage = await getCroppedImg(
        avatar,
        croppedAreaPixels,
        rotation
      )
      handleSubmit(croppedImage, alertfy)
    } catch (err) {
      console.error(err)
    }
  }, [croppedAreaPixels, rotation])


  const handleCrop = (e: any, alertfy: (text: string) => void) => {
    e.preventDefault()
    showCroppedImage(alertfy)

  }

  return (
    <div className='Wrap-all-EasyCrop'>
      <div className="EasyCrop">
        <div className="crop-container">
          <Cropper
            image={avatar}
            crop={crop}
            rotation={rotation}
            zoom={zoom}
            aspect={3 / 3}
            onCropChange={setCrop}
            onRotationChange={setRotation}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
        </div>
        <div className="controls">
          <div className='crop-buttons'>
            <span className='center' onClick={() => setVisible(false)}>
              <IoClose />
            </span>
            <div className='crop-buttons-button'>
              <FormButton
                spinner={spinner}
                space={0}
                text='Aplicar'
                onClick={handleCrop}
              />
            </div>

          </div>
          <input
            type="range"
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            aria-labelledby="Zoom"
            onChange={(e: any) => {
              setZoom(e.target.value)
            }}
            className="zoom-range"
          />
        </div>
      </div>
    </div>
  )
}

export default EasyCrop

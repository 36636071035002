import { useEffect, useState } from 'react'
import './styles.css'

// Components:
import SafeDiv from '../../SafeDiv'

import WcFrist from './nested/WcFrist';
import WcLocation from './nested/WcLocation';
import api from '../../../config/connection/axios';
import { IoLocationSharp } from 'react-icons/io5';
import Parting from './nested/Parting';

import WcMessage from './nested/WcMessage';

import headers from '../../../constants/localStorage/headers';
import CreateWorkContext from './context';
import WcFinishBtn from './nested/WcFinishBtn';
import WcGreenBack from './nested/WcGreenBack';
import { subwork_, subworks_ } from '../DetailsContainer/interface';
import { locationData_ } from '../../GetLocation/interface';
import shake from '../../../languages/windowAlertForm.json';
import min from '../../../languages/pt-BR/minPrice.json';
import { getUser } from '../../../constants/localStorage/User';

function WorkCreateContainer() {
    const User = getUser();

    const [spinner, setSpinner] = useState<boolean>(false);
    const [showMessage, setShowMessage] = useState<number | false>(false);

    const [title, setTitle] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [price, setPrice] = useState<number>(0);
    const [phone, setPhone] = useState<string>(User.phone || '');
    const [subworks, setSubworks] = useState<subworks_>([]);
    const [locationData, setLocationData] =
        useState<locationData_>(
            { state_name: 'Todo o Brasil' } as locationData_
        );

    const notAllowed = (alertfy: any) => {
        if (!(
            title && price >= 50
            && phone && locationData &&
            locationData.city_id
        )) { alertfy(shake); return true }

        if (typeof title !== 'string') { alertfy('Nome do serviço deve ter no mínimo 3 caracteres'); return true }
        if (title.trim().length < 3) { alertfy('Nome do serviço deve ter no mínimo 3 caracteres'); return true };
        if (price < 50) { alertfy(min); return true };
        if (description && description.trim().length > 510) { alertfy('Descrição deve ter no máximo 510 caracteres'); return true };

        return false;
    }

    const CreateWorkSubmit = async (e: any, alertfy: any) => {
        e.preventDefault();
        if (notAllowed(alertfy)) return;
        setSpinner(true);

        const body = {
            title,
            description,
            price,
            city_id: locationData.city_id,
            subworks,
            phone: (phone !== User.phone) ? phone : null
        }

        await api.post(
            `/works/subworks/create`,
            body,
            { headers: headers() }
        )
            .then(r => {
                localStorage.removeItem('wc-awaiting-payment');
                const result: { work_id: number } = r.data;
                return setShowMessage(result.work_id);
            })
            .catch(err => {
                setSpinner(false)
                if (
                    err.response &&
                    err.response.data &&
                    typeof err.response.data.message === 'string'
                ) alertfy(err.response.data.message)
                else alertfy('Por favor tente novamente mais tarde');
            })

    }

    const GenerateProPayment = (e: any, alertfy: any) => {
        e.preventDefault();
        if (notAllowed(alertfy)) return;
        setSpinner(true);

        const body = {
            title,
            description,
            price,
            locationData,
            subworks
        }
        localStorage.setItem('wc-awaiting-payment', JSON.stringify(body));

        api.get('/pix/generate/pro', { headers: headers() })
            .then(r => {
                const result = r.data;
                setSpinner(false);
                window.open(result.linkBuyMercadoPago, '_self');
            })
    }

    const handleSubmit = (e: any, alertfy: any) => {
        isPro
            ? CreateWorkSubmit(e, alertfy)
            : GenerateProPayment(e, alertfy);
    }

    const [isPro, setIsPro] = useState<boolean>(false);
    useEffect(() => {
        const handleIsPro = async () => {
            await api.get('/is/pro', { headers: headers() })
                .then(r => {
                    const result: { isPro: boolean } = r.data;
                    console.log(result.isPro);
                    setIsPro(result.isPro);
                })
        }

        try {
            const awaitingPayment =
                JSON.parse(
                    localStorage
                        .getItem('wc-awaiting-payment') || '{}'
                );
            setTitle(awaitingPayment.title || '');
            setDescription(awaitingPayment.description || '');
            setPrice(awaitingPayment.price || 0);
            setSubworks(awaitingPayment.subworks || []);
        } catch (err) {
            console.error(err)
        }

        handleIsPro();
    }, [])

    const handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            e.preventDefault()
        }
    }

    const pushX = (sw: subwork_) => {
        setSubworks(sws => ([...sws, sw]));
    }

    const delX = (swid: number) => {
        console.log('swid', swid);
        setSubworks(sws => (sws.filter(sw => (sw.id !== swid))));
    }

    const editX = (swe: subwork_) => {
        setSubworks(sws => (sws.map(sw => ((sw.id === swe.id) ? swe : sw))));
    }


    return (
        <CreateWorkContext.Provider
            value={{
                work: {
                    title, setTitle,
                    description, setDescription,
                    price, setPrice,
                    phone, setPhone,
                    locationData,
                    setLocationData
                },
                subworks,
                setSubworks: {
                    push: pushX,
                    del: delX,
                    edit: editX
                }
            }}
        >
            {
                showMessage && <WcMessage
                    showMessage={showMessage}
                />
            }

            <WcGreenBack />
            <SafeDiv>
                <div id='WorkCreate' className='center column' onKeyDown={handleKeyDown}>
                    <form>
                        <h2>Quais serviços você está anunciando?</h2>
                        <WcFrist />
                        <Parting
                            text='Localização'
                            icon={<IoLocationSharp size={20} color='#75757599' />}
                        />
                        <WcLocation />
                        <WcFinishBtn
                            spinner={spinner}
                            handleSubmit={handleSubmit}
                            isPro={isPro}
                        />
                    </form>
                    <div style={{ height: '160px' }} />
                </div>
            </SafeDiv>
        </CreateWorkContext.Provider >
    )
}

export default WorkCreateContainer;
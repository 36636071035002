const SocNavBar = ({ switcher, setSwitcher }: { switcher: any, setSwitcher: any }) => {
    return (
        <div id='OrdersNavigation' style={{ height: '70px' }} className="center">
            <div style={{
                width: '95%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
            }}>


                <SocNavBarBtn
                    switcher={switcher}
                    setSwitcher={setSwitcher}
                    text="Compras"
                    page={2}

                />
                <SocNavBarBtn
                    switcher={switcher}
                    setSwitcher={setSwitcher}
                    text="Vendas"
                    page={3}
                />
            </div>
        </div>
    )
}

const SocNavBarBtn = (
    { text, page, switcher, setSwitcher }: { switcher: any, setSwitcher: any, text: string; page: 2 | 3 }
) => {


    const changeSwitcher = (page: 2 | 3) => {
        setSwitcher(page)
    }



    return (
        <button
            onClick={() => changeSwitcher(page)}
            className={`center column soc-nav-btn soc-nav-btn--${switcher === page && 'selected'}`}
        >
            <div>
                <strong style={{ margin: 0, fontSize: '13px' }}>{text}</strong>
            </div>
        </button>
    )
}

export default SocNavBar
import TrashButton from "../../../Buttons/TrashButton"

const Parting = ({ text, icon, retract, border = true }: { text: string, icon: any, retract?: (e: Event) => void, border?: boolean }) => (
    <div style={{
        borderTop: border ? '30px solid #eeeeee' : '',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        width: '110%',
        margin: '30px 0 0 -5%',
        paddingTop: '30px',
        position: 'relative'
    }}>
        <span
            style={{
                borderRadius: '8px',
                marginRight: '6px',
                marginLeft: '-5%'

            }}
            className='center'
        >
            {icon}
        </span>
        <strong style={{ color: '#757575' }}>{`${text}`.toUpperCase()}</strong>
        {retract &&
            <div
                style={{
                    position: 'absolute',
                    right: '20px',
                    top: 16,
                }}
            >
                <TrashButton funk={retract} backgroundColor='transparent' />
            </div>}
    </div>
)

export default Parting

import { IoChevronBack } from 'react-icons/io5'
import CatList from './CatList'

function ShLiMainCat({ setSwitcher }: { setSwitcher: (any: any) => void }) {
    return (

        <ul className='SB-list' style={{ minHeight: '100vh' }}>
            <li style={{ padding: '20px 0 20px 16px' }}>
                <div className='SB-list-li-user-container'>
                    <div
                        className='SB-list-li-user-info SB-list-li-user-info-hover '
                        onClick={() => setSwitcher('default')}
                    >
                        <span className='center'>
                            <IoChevronBack />
                        </span>
                        <span className='SB-list-li-user-text'>Principais Categorias</span>
                    </div>
                </div>
            </li>
            <li>
                <CatList />
            </li>
        </ul>

    )
}

export default ShLiMainCat
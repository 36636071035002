import { AiOutlineThunderbolt } from "react-icons/ai";
import { VscSymbolColor } from "react-icons/vsc";
import { TbMassage } from "react-icons/tb";
import { MdOutlineDesignServices, MdSavedSearch } from "react-icons/md";
import { TbDeviceDesktopAnalytics } from "react-icons/tb";
import { IoKeyOutline } from "react-icons/io5";

import Icon from '../../IconMarginRight'
import './styles.css'
import { Link } from 'react-router-dom';


function PopularCategories({ children }: any) {

    const iconsList = [
        { icon: <AiOutlineThunderbolt />, text: 'Eletricista', to: '/search?search=eletricista' },
        { icon: <VscSymbolColor />, text: 'Pintura', to: '/search?search=pintor' },
        { icon: <IoKeyOutline />, text: 'Chaveiro', to: '/search?search=chaveiro' },
        { icon: <TbDeviceDesktopAnalytics />, text: 'Tecnologia', to: '/search?search=tecnico' },
        { icon: <TbMassage />, text: 'Massagem', to: '/search?search=Terap' },
        { icon: <MdOutlineDesignServices />, text: 'Design', to: '/search?search=design' },
        { icon: <MdSavedSearch />, text: 'Explorar', to: '/search?search=' },
    ]

    return (
        <div className='Hide-popC'>
            {children}
            <div id='PopularCategories'>
                <ul>
                    {iconsList.map((icon, idx) => (
                        <Link to={icon.to} key={idx}>
                            <li className='center column'>
                                <Icon>
                                    {icon.icon}
                                </Icon>
                                <p>{icon.text}</p>
                            </li>
                        </Link>
                    ))}
                </ul>
            </div>
        </div>
    )
}

export default PopularCategories
import { useContext, useState } from "react";
import api from "../../../../config/connection/axios";
import FormButton from "../../../Form/Inputs/nested/FormButton";

import RegCheck from "./RegCheck";
import WindowAlertContext from "../../../../context/windowAlert.context";
import headers from "../../../../constants/localStorage/headers";

function RegCResetPassword() {
    const windowAlert = useContext(WindowAlertContext);

    const [sent, setSent] = useState<boolean>(false)
    const [spinner, setSpinner] = useState<boolean>(false);

    const handleSubmit = async () => {

        setSpinner(true)

        await api.get(
            '/code/generate/utoken',
            {headers:headers()}
        )
            .then(r => {
                setSent(true)
                setSpinner(false)
            })
            .catch(() => {
                setSpinner(false);

                windowAlert?.confirm({ shake: 'Falha ao enviar email, tente novamente mais tarde.' })
            })


    }

    return (
        <form>
            <div className='RegC-title'>
                <h4>Alteração de senha</h4>
                <p>Ao confirmar você recebera um e-mail com as instruções e o link para criar uma nova senha.</p>
            </div>
            <div className='reg-default-buttons'>
                {
                    sent
                        ? <RegCheck />
                        : <FormButton
                            text='Alterar senha'
                            style={{
                                borderRadius: '100px',
                                backgroundColor: '#009b6730',
                                color: '#00ae74',
                                border: '0px'
                            }}
                            onClick={(e: Event) => {
                                e.preventDefault();
                                windowAlert?.confirm(
                                    {
                                        strong: "Alteração de senha",
                                        p: "Deseja confirmar a alteração de senha?"
                                    },
                                    handleSubmit
                                )
                            }}
                            spinner={spinner}
                        />
                }
            </div>
        </form>
    )
}

export default RegCResetPassword
import { useState } from 'react'
import api from '../../config/connection/axios'
import regex from '../../functions/regex'
import FormButton from '../Form/Inputs/nested/FormButton'
import Input from '../Form/Inputs/nested/Input'

import Modal from '../Modal'
import './styles.css'


interface ISendResetPassword {
    email: string,
    setEmail: (value: string) => void
}

function SendResetPassword({ email, setEmail }: ISendResetPassword) {

    const [sent, setSent] = useState<string>('')
    const [spinner, setSpinner] = useState<boolean>(false)
    const [visible, setVisible] = useState<boolean>(false)

    const handleResetPassword = async (e: any, alertfy: any) => {
        e.preventDefault()

        if (!regex.email.test(email.trim())) return alertfy('Por favor informe um e-mail válido')

        setSpinner(true)

        await api.get(`/code/generate/utoken/${email}`)
            .then(() => {
                setSpinner(false)
                setSent(email)
            })
            .catch(() => {
                setSpinner(false)
                alertfy('Falha ao enviar email, tente novamente mais tarde')
            })
    }

    return (
        <div>
            <p className='SRP-btn' onClick={() => setVisible(true)}>redefinir senha</p>
            <Modal
                visible={visible}
                setVisible={setVisible}
                children={
                    sent
                        ? <div style={{ width: '400px' }}>
                            <strong className='SRP-stron'>E-mail enviado</strong>
                            <p className='SRP-text'>{`E-mail enviado para ${email} com as instruções e o link para você trocar a senha. se você não receber o e-mail em alguns minutos, verifique a sua caixa de spam ou tente novamente.`}</p>
                        </div>
                        : <form style={{ width: '400px' }}>
                            <strong className='SRP-stron'>Redefinir senha</strong>
                            <p className='SRP-text'>Identifique-se para receber um e-mail com as instruções e o link para criar uma nova senha.</p>
                            <Input
                                label='E-mail'
                                placeholder='exemplo@email.com'
                                value={email}
                                onChange={setEmail}
                            />
                            <FormButton
                                text='Enviar'
                                onClick={handleResetPassword}
                                spinner={spinner}
                            />
                        </form>
                }
            />
        </div>
    )
}

export default SendResetPassword
import { useContext, useState } from "react";
import api from "../../../../config/connection/axios";
import FormButton from "../../../Form/Inputs/nested/FormButton";

import RegCheck from "./RegCheck";
import Input from "../../../Form/Inputs/nested/Input";
import WindowAlertContext from "../../../../context/windowAlert.context";
import headers from "../../../../constants/localStorage/headers";

function RegCAskToRemoveData() {
    const [sent, setSent] = useState<boolean>(false)
    const [spinner, setSpinner] = useState<boolean>(false);
    const [message, setMessage] = useState<string>('');

    const windowAlert = useContext(WindowAlertContext);

    const handleSendEmail = async (e: any, alertfy: any) => {
        e.preventDefault();

        if (message === '') return  windowAlert?.confirm({shake:'Informe quais dados você deseja excluir.'});

        const u = JSON.parse(window.localStorage.getItem('serss-user') || '{}');

        setSpinner(true)

        await api.put(
            '/feedback/manage/data',
            {
                suggestion: '[delete_this_data]' + message,
                evaluation: '',
                uid: u.uid,
                email: 'not_defined',
                phone: 'not_defined',
                name: u.name,
                code: 'manage_data'
            },
            {headers:headers()}
        )
            .then(r => {
                setSpinner(false);
                setSent(true);
                windowAlert?.confirm({strong:"Excluir informações", p:"Aguarde, responderemos o mais rápido possível."})
            })
            .catch(() => {
                setSpinner(false);
                alertfy('Por favor tente novamente mais tarde');
            })
    }

    return (
        <form>
            <div className='RegC-title'>
                <h4>Pedir para excluir informações</h4>
                <p>
                    Se você deseja excluir determinados dados, mas não encontrou a opção, basta enviar uma mensagem especificando quais dados são, e nossa equipe técnica os removerá para você.
                </p>                
                <p>
                    <strong>Importante:</strong> Ao excluir sua conta todos seus dados são removidos.
                </p>
            </div>
            <div>
                <Input
                    placeholder="Quais dados você deseja excluiruir?"
                    space={0}
                    value={message}
                    onChange={setMessage}
                />
            </div>
            <div className='reg-default-buttons'>
                {
                    sent
                        ? <RegCheck />
                        : <FormButton
                            text='Pedir remoção'
                            style={{
                                borderRadius: '100px',
                                backgroundColor: 'rgb(240, 190, 181)',
                                color: 'tomato',
                                border: '0px'
                            }}
                            onClick={handleSendEmail}
                            spinner={spinner}
                        />
                }
            </div>
        </form>
    )
}

export default RegCAskToRemoveData;
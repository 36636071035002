import { useState } from 'react'
import { IoArrowForward, IoChevronDown, IoClose } from 'react-icons/io5';
import { Link } from 'react-router-dom';

interface ScAlert_ {
    title: string,
    content: JSX.Element,
    custom?: {
        extendContent?: JSX.Element,
        hideIcon?: boolean,
        blueMore?: boolean,
        button?: {
            text: string
            onClick: any
        }
    }
}

function ScAlert({ custom, title, content }: ScAlert_) {
    const [expandSc, setExpandSc] = useState<boolean>(false);

    return (
        <>
            <div className='center' style={{ margin: '100px 0' }}>
                <div
                    style={{
                        backgroundColor: 'white',
                        padding: '40px',
                        borderRadius: '8px',
                        boxShadow: '7px 7px 16px 0 rgb(0 0 0 / 10%)',
                        width: '960px',
                        maxWidth: 'calc(90vw - 80px)'
                    }}


                >
                    {
                        custom?.button &&
                        <div style={{ marginTop:'-10px',width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                            <button
                                onClick={custom.button.onClick}
                                style={{
                                    border: '1px solid black',
                                    borderRadius: '8px',
                                    height: '36px',
                                    width: '36px',
                                    fontSize: '18px'
                                }}
                                className='center'
                            >
                                <IoClose />
                            </button>
                        </div>
                    }
                    <div style={{ marginBottom: custom?.extendContent ? 0 : '40px' }}>
                        <div style={{ display: 'flex' }}>
                            {
                                !custom?.hideIcon &&
                                <span className='center' style={{ marginRight: '8px' }}>
                                    ⚠️
                                </span>
                            }
                            <h3>{title}</h3>
                        </div>
                        {
                            (expandSc && custom?.extendContent)
                                ? <>{custom?.extendContent}</>
                                : <>{content}</>
                        }
                        {
                            custom?.extendContent &&
                            <div style={{ margin: '20px 0' }} className='center'>
                                {
                                    !expandSc &&

                                    <button className='center' style={{ color: custom?.blueMore ? 'dodgerblue' : '#696969' }} onClick={() => setExpandSc(true)}>
                                        <p style={{ margin: '4px 0' }}>Saiba mais </p>
                                        <span className='center' style={{ marginLeft: '8px' }}><IoChevronDown /></span>
                                    </button>

                                }
                            </div>
                        }
                    </div>


                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        {
                            custom?.button &&
                            <button
                                onClick={custom.button.onClick}
                                className='center'
                                style={{ position: 'relative', borderRadius: '8px', height: '50px', width: '100%', backgroundColor: 'dodgerblue', color: 'white', fontWeight: 'bold' }}
                            >
                                {custom.button.text}
                            </button>
                        }
                    </div>
                    {
                        (custom && custom.button && custom.button.text === 'Nesses golpes eu não caio!') &&
                        <div className='center'>
                            <Link to='/central#3' className='center' style={{ fontWeight: 'bold', marginTop: '20px' }}>Proteja-se <span className='center' style={{ marginLeft: '6px' }}><IoArrowForward /></span></Link>
                        </div>
                    }
                </div>
            </div>
        </>
    )
}

export default ScAlert;
import { IoLogOutOutline } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom'
import api from '../../../../config/connection/axios'

import { useContext } from 'react';
import WindowAlertContext from '../../../../context/windowAlert.context';
import headers from '../../../../constants/localStorage/headers';

function RegLogOut() {
    const windowAlert = useContext(WindowAlertContext);

    const navigate = useNavigate()

    const handleLogOut = () => {

        const u = JSON.parse(window.localStorage.getItem('serss-user') || '{}')

        api.delete(
            '/users/logout',
            {headers:headers()}
        )

        window.localStorage.removeItem('serss-user')
        navigate('/')

    }

    return (
        <div
            onClick={() => windowAlert?.confirm(
                {
                    strong: 'Logout',
                    p: 'Você realmente quer sair?'
                },
                handleLogOut
            )}
            className='center'
            style={{
                fontWeight: 'bold',
                marginTop: '28px',
                color: '#696969',
                cursor: 'pointer',
                backgroundColor: '#69696930',
                padding: '6px 88px',
                borderRadius: '12px'
            }}
        >
            <p className='ellipsis'>Sair</p>
            <span className='center' style={{ marginLeft: '10px' }}>
                <IoLogOutOutline size={28} />
            </span>
        </div>
    )
}

export default RegLogOut
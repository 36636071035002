//Componets:
import HomeContainer from "../components/pageContent/HomeContainer";
import Footer from "../components/Footer";
import Header from "../components/Headers/Header";
import NavigationBar from "../components/mobileComponents/NavigationBar";

function Home() {
    window.scrollTo(0, 0);

    document.title = 'Servicess: Encontre Serviços'

    return (
        <>
            <Header
                home={true}
            />
            <HomeContainer />
            <Footer />
            <NavigationBar />
        </>
    );
}

export default Home;
import { IoArrowForward, IoChatbubbleOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import "./style.css";

export default function Footer({ smaller = false }: { smaller?: boolean }) {

    return (
        <footer id="Footer" style={smaller ? { flexDirection: 'row' } : {}}>
            <Link to='/central'
                className="center footer-central"
            >
                <span
                    className="center"
                    style={{ position: 'relative' }}
                >
                    <IoChatbubbleOutline size={30} />
                    <strong
                        style={{
                            fontFamily: 'montserrat',
                            fontSize: '12px',
                            position: 'absolute',
                            paddingTop: '1px'
                        }}
                    >
                        S
                    </strong>
                </span>
                <strong
                    style={{
                        marginLeft: '10px'
                    }}
                >
                    Central de atendimento
                </strong>
                <div
                    className="center f-central-hvr"
                    style={{ color: 'white' }}
                >
                    <span className='center'>
                        <IoArrowForward />
                    </span>
                </div>
            </Link>
            {
                true && (
                    <div
                        style={{ opacity: .4 }}
                        className='f f--logo'
                    >
                        <img alt='servicess logotype'src={require("../../assets/logo.png")} />
                    </div>
                )
            }
            <h2
                style={{ opacity: .4 }}
            >
                Servicess
            </h2>
            {
                true &&
                <div className='f f--contatos' >
                    <Link
                        to='/central#3'
                        className="footer-central-3"
                    >
                        <div
                            className="center"
                            style={{
                                backgroundColor: "#c4c4c415",
                                minHeight: '50px',
                                height: '100%',
                                width: '50px',
                                borderRadius: '10px',
                                marginRight: '20px'
                            }}
                        >
                            🚨
                        </div>
                        <div
                            style={{ fontSize: '18px' }}
                        >
                            <p
                                className='conts'
                                style={{
                                    margin: '0'
                                }}
                            >
                                Recebeu um contato duvidoso?


                            </p>
                            <p
                                className='conts'
                                style={{
                                    margin: '10px 0 0 0',
                                    opacity: .8
                                }}
                            >
                                Proteja-se aqui ➜
                            </p>
                        </div>

                    </Link>
                    <p
                        style={{
                            color: 'var(--green)',
                            fontWeight: 'normal'
                        }}
                    >
                        clique no botão acima ↑
                    </p>
                </div>
            }
            <div style={{ height: '20px' }} />
            <div
                className='f f--copyRight column'
                style={{ opacity: .4 }}
            >
                {
                    true &&
                    <p>Servicess LTDA / Todos os direitos reservados.<br />CNPJ n.º 48.200.886/0001-43</p>
                }
                <div className="center">
                    <p style={{ color: 'white', margin: '0 40px 0 0' }}><Link to='/terms'>termos e condições de uso</Link></p>
                    <p style={{ color: 'white', margin: '0' }}><Link to='/politica_de_privacidade'>política de privacidade</Link></p>
                </div>
            </div>
        </footer>
    );
}
import ThinHeader from '../components/Headers/ThinHeader';
import NavigationBar from '../components/mobileComponents/NavigationBar';
import SafeDiv from '../components/SafeDiv';
import SideBarContent from '../components/SideBar/nested/SideBarContent';
import isMobile from '../functions/isMobile';

function Menu() {
  return (
    <div>
      <ThinHeader />
      <div style={{ height: 'var(--header-height)' }} />
      <SideBarContent />
      {
        isMobile() &&
        <div style={{
          height: '90px',
          width: '100%',
          backgroundColor: 'white'
        }}
        />
      }

      <NavigationBar />
    </div>
  )
};

export default Menu;
import "./style.css";

export default function AboutUs() {

    const txts = {
        "title": "Servicess",
        "description": [
            "A Servicess é a nova plataforma de serviços do Brasil. Conectando pessoas diariamente de forma gratuita. Divulgue seu trabalho, encontre serviços, profissionais e empresas na sua região."
        ]
    };



    return (
        <div id="AboutUs">
            <div className='gradient gradient--left'>
                <div className="sobre_titulo">
                    {window.innerWidth < 960 ? <AboutImgOne /> : <AboutImgTwo />}
                    <div className="sobre_titulo-comserv">
                        <span>Comunidade</span>
                        <h2>{txts.title}</h2>
                    </div>
                </div>
                <div className="sobre_texto">
                    {txts.description.map((paragraph, idx) => (
                        <p
                            key={idx}
                        >
                            {paragraph}
                        </p>
                    ))}
                </div>

            </div>
            <div className='gradient gradient--right'>
                <img src={'https://st.depositphotos.com/1700950/1220/i/600/depositphotos_12205740-stock-photo-closeup-of-a-business-hand.jpg'} />
                <div className='gradient_absolut_right'></div>
            </div>
        </div>
    );
}

const AboutImgOne = () => (
    <div className='center sobre_titulo_img_one' style={{ backgroundColor: '#222D36', borderRadius: '100%', height: '84px', width: '84px', marginRight: '14px' }}>
        <img src={require('../../../assets/logo.png')} alt='Logotipo da comunidade servicess' />
    </div>
)

const AboutImgTwo = () => (
    <img className='sobre_titulo_img_two' src={require('../../../assets/servicesscommunity.png')} alt='Logotipo da comunidade servicess' />
)

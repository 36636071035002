import {
    BrowserRouter as Router,
    Routes,
    Route
} from "react-router-dom";

//Docs:
import TermsOfUse from "./pages/documentationX/TermsOfUse/TermsOfUse";
import PrivacyPolicy from "./pages/documentationX/PrivacyPolicy/PrivacyPolicy";
//Pages:
import Home from "./pages/Home";
import Login from "./pages/Login";
import P404 from "./pages/P404";
import Search from "./pages/Search";
import Signin from "./pages/Signin";
import ResetPassword from "./pages/validationCodes/ResetPassword";
import Validation from "./pages/validationCodes/Validation";
import CreateWork from "./pages/works/CreareWork";
import Registration from "./pages/Registration";
import Categories from "./pages/Categories";


import Central from "./pages/Central";
import ChatPage from "./pages/ChatPage";
import Menu from './pages/Menu'
// Orders - Nested:
import Orders from "./pages/Orders";
// Details - Nested:
import Details from "./pages/Details";
import DetailsSelected from "./components/pageContent/DetailsContainer/nested/outlet/DetailsSelected";
import DetailsDefault from "./components/pageContent/DetailsContainer/nested/outlet/DetailsDefault";
import ListWorks from "./pages/works/ListWorks";

function RoutesControl() {
    return (
        <Router>
            <Routes>
                <Route
                    path="*"
                    element={<P404 />}
                />
                <Route
                    path="/"
                    element={<Home />}
                />
                 <Route
                    path="/menu"
                    element={<Menu />}
                />
                  <Route
                    path="/search"
                    element={<Search />}
                />
                <Route
                    path="/login"
                    element={<Login />}
                />
                <Route
                    path="/signin"
                    element={<Signin />}
                />
                <Route
                    path="/details/:work_id"
                    element={<Details />}
                >
                    <Route
                        path=""
                        element={<DetailsDefault />}
                    />
                    <Route
                        path="selected"
                        element={<DetailsSelected />}
                    />
                </Route>
                <Route
                    path="/central"
                    element={<Central />}
                />
                <Route
                    path="/validation"
                    element={<Validation />}
                />
                <Route
                    path="/reset/password/:token"
                    element={<ResetPassword />}
                />
                <Route
                    path="/work/create"
                    element={<CreateWork />}
                />
                <Route
                    path="/cards/belongs"
                    element={<ListWorks />}
                />
                <Route
                    path="/registration"
                    element={<Registration />}
                />
                <Route
                    path="/categories"
                    element={<Categories />}
                />
                <Route
                    path="/terms"
                    element={<TermsOfUse />}
                />
                <Route
                    path="/politica_de_privacidade"
                    element={<PrivacyPolicy />}
                />
                <Route
                    path="/orders"
                    element={<Orders />}
                />
                <Route
                    path="/chat"
                    element={<ChatPage />}
                />
            </Routes>
        </Router>
    );
}

export default RoutesControl;
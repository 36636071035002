
import { useParams } from "react-router-dom";

import Cardboard from "../../components/pageContent/Cardboard"
import ResetPasswordContainer from "../../components/pageContent/ResetPasswordContainer";
import ThinHeader from "../../components/Headers/ThinHeader"

function ResetPassword() {

    const { token } = useParams();

    return (
        <>
            <ThinHeader />
            <Cardboard
                children={
                    <ResetPasswordContainer token={token} />
                }
            />
        </>
    )
}

export default ResetPassword
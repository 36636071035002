import { useState } from "react";
import ScAlert from "../ScAlert"
import { Link } from "react-router-dom";

function ScAlertHome({ always = false }: { always?: boolean }) {

    const [sc_alert, setSc_alert] = useState(window.localStorage.getItem('serss-sc-alert'));

    const handleNoSc = () => {
        window.localStorage.setItem('serss-sc-alert', 'understood')
        setSc_alert('understood')
    }

    return (
        <>
            {
                (sc_alert !== 'understood' || always) &&
                <ScAlert
                    title="ALERTA CONTRA GOLPE"
                    content={
                        <div>
                            <p>Prezados clientes e parceiros,</p>
                            <p style={{ lineHeight: 1.6 }}>
                                Golpistas estão alegando que anúncios foram criados usando suas imagens para passar golpes. Queremos reforçar: <strong>isso é absolutamente FALSO</strong>.
                                <br />
                                <br />
                                <strong>Não</strong> realizem nenhum pagamento nem compartilhem seus dados
                                <br />
                                <br />
                            </p>
                        </div>
                    }
                    custom={{
                        extendContent:
                            <div>
                                <div style={{ border: '1px solid grey', padding: '0 15px', borderRadius: '12px' }}>
                                    <p style={{ lineHeight: 1.6 }}>
                                        Golpistas estão alegando que anúncios foram criados <strong>usando suas imagens</strong> para passar golpes. Queremos reforçar: <strong>isso é absolutamente Falso</strong>.
                                    </p>
                                    <p style={{ lineHeight: 1.6 }}>
                                        Pedimos que <strong>NÃO realizem nenhum pagamento</strong> nem compartilhem seus dados. Se receberem qualquer solicitação suspeita, entrem em <Link style={{ color: 'dodgerblue' }} to='/central#2'><strong>contato</strong></Link> conosco imediatamente.
                                    </p>
                                </div>
                                <br />
                                <p style={{ lineHeight: 1.6 }}>
                                    Gostaríamos de informar que nossa empresa <strong>NUNCA</strong> realiza vendas por <strong>telefone ou WhatsApp</strong> e <strong>NÃO COBRA</strong> por <strong>clientes</strong> de forma alguma. Recentemente, temos sido alertados sobre golpistas que se fazem passar por representantes da nossa empresa e tentam enganar as pessoas, solicitando <strong>pagamentos indevidos</strong> ou informações pessoais.
                                    <br /><br />
                                    Por favor, esteja ciente de que:
                                </p>
                                <ol>
                                    <li>
                                        <p style={{ lineHeight: 1.6 }}>
                                            <strong>Vendas por Telefone:</strong> Nós não realizamos vendas por telefone. Se alguém entrar em contato afirmando ser da nossa empresa e oferecendo serviços ou produtos por telefone, isso é um golpe.
                                        </p>
                                    </li>
                                    <br />
                                    <li>
                                        <p style={{ lineHeight: 1.6 }}>
                                            <strong>Cobranças:</strong> Nós NÃO cobramos por clientes ou leads. Qualquer pedido de pagamento nesse contexto é falso e deve ser ignorado.
                                        </p>
                                    </li>
                                </ol>
                                <br />  <br />
                                Pedimos a todos que estejam atentos a essas tentativas de fraude e não forneçam informações pessoais, detalhes de cartão de crédito ou efetuem pagamentos a esses impostores.
                                <br />  <br />
                                Caso você seja contatado por alguém suspeito ou tenha dúvidas sobre a autenticidade de qualquer comunicação relacionada à nossa empresa, entre em contato conosco diretamente pelos meios de contato oficial que você encontra em nosso site ou registros comerciais.
                                <br />  <br />
                                A sua segurança e a integridade das nossas operações são de extrema importância para nós. Estamos trabalhando ativamente para identificar e denunciar essas atividades fraudulentas às autoridades competentes.
                                <br />  <br />
                                Lembre-se, a melhor defesa contra golpes é a informação e a vigilância. Compartilhe esta informação com amigos, familiares e colegas para ajudar a proteger todos nós contra essas tentativas de fraude.
                                <br />  <br />
                                Atenciosamente,<br />
                                Equipe Servicess.
                                <br />  <br />
                            </div>,
                        button: !always
                            ? {
                                text: 'Nesses golpes eu não caio!',
                                onClick: handleNoSc

                            }
                            : undefined

                    }}
                />}
        </>
    )
}

export default ScAlertHome
import Banner from "../Banner"
import './styles.css'

interface ImagesCardSlider_ {
    hideWrapper: any,
    setHideWrapper: any,
    user_uid: string,
    id: string,
    alias: string,
    type: 'card' | 'details',

}

function ImagesCardSlider({ type, alias, hideWrapper, setHideWrapper, user_uid, id }: ImagesCardSlider_) {
    return (
        <>
            {
                window.innerWidth < 960 &&
                <div className={`wrapper-${alias}`}>
                    {
                        !hideWrapper.zero && <div id={alias} className={`center ${(hideWrapper.one && hideWrapper.two && hideWrapper.three) ? `full-${alias}` : ''}`}>
                            <Banner
                                uid={user_uid}
                                wid={id}
                                current={'card'}
               
                                type="main"
                                hideWrapper={() => setHideWrapper({ ...hideWrapper, zero: true })}
                            />
                        </div>
                    }
                </div>
            }
        </>
    )
}

export default ImagesCardSlider;
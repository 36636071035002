import { FaHandHoldingHeart } from "react-icons/fa"
import { IoLogoWhatsapp } from "react-icons/io5"
import FormButton from "../../Form/Inputs/nested/FormButton"
import Icon from "../../IconMarginRight"
import { Link } from "react-router-dom"

const ContactUsCannot = ({ children }: { children: JSX.Element }) => (
    <>
        <div style={{ padding: '0 20px', border: '1px solid #c4c4c4', borderRadius: '10px', marginTop: '20px' }}>
            <div style={{ display: 'flex', color: '#222D36' }}>
                <Icon right={20}>
                    <FaHandHoldingHeart size={22} />
                </Icon>
                <p>Se não conseguir criar sua conta entre em contato com nossa 
                    <strong><Link to='/central' style={{ textDecoration: 'underline' }}>{' Central de atendimento'}</Link></strong>
                </p>

            </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ width: '48%' }}>


                <FormButton
                    onClick={
                        (e: any) => {
                            e.preventDefault();
                            window.open('https://api.whatsapp.com/send?phone=5541991346013&text=Olá,%20quero%20ajuda%20para%20criar%20minha%20conta!')
                        }
                    }
                    style={{ backgroundColor: '#222D36', border: '1px solid #222D36' }}
                    text={<div className='center'>
                        <Icon right={8}>
                            <IoLogoWhatsapp size={18} />
                        </Icon>
                        Assistência
                    </div>}
                />
            </div>
            <div style={{ width: '48%' }}>
                {children}
            </div>
        </div>
    </>
)

export default ContactUsCannot

import CentralSelector from '../../CentralSelector'

import { IoCreateOutline, IoTrashBinOutline } from 'react-icons/io5'
import currency from '../../../functions/currency'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import api from '../../../config/connection/axios'
import headers from '../../../constants/localStorage/headers'
import hideContactsDescription from '../../../functions/hideContactsDescription.simple'

interface work_ {
    id: number,
    title: string,
    description: string,
    price: number
}

function ListWorksContainer() {

    const [works, setWorks] = useState<work_[]>([]);

    useEffect(() => {
        const handleSubmit = async () =>
            await api.get('/cards/belongs', { headers: headers() })
                .then(r => {
                    const result: work_[] = r.data;
                    setWorks(result);
                })
                .catch(err => console.error(err));

        handleSubmit();
    }, [])

    return (
        <div id='WorkCreate' className='center column'>

            <h2>Meus anúncios</h2>
            <div style={{ width: '90%' }}>
                <CentralSelector
                    strong='Ativos'
                />
            </div>
            <ul
                className='center column'
                style={{
                    width: '100%'
                }}
            >
                {
                    works.map((it, idx) =>
                        <ListWorksContainerLi work={it} key={idx} />
                    )
                }
            </ul>

            <div style={{ height: '160px' }} />
        </div>
    )
}

const ListWorksContainerLi = ({ work }: { work: any }) => {
    return (
        <li style={{
            width: '90%',
            display: 'flex',
            padding: '30px 0',
            borderBottom: '1px solid var(--border)',
            flexDirection: 'column'
        }}>
            <Link to={`/details/${work.id}`}>
                <div style={{ display: 'flex' }}>
                    <div
                        style={{
                            marginRight: '20px'
                        }}
                    >
                        <div
                            style={{
                                position: 'relative',
                                width: '80px',
                                height: '80px',
                                backgroundColor: 'var(--border)',
                                borderRadius: '12px'
                            }}
                        />
                    </div>
                    <div>
                        <strong>{work.title}</strong>
                        <p style={{ opacity: .7, margin: '20px 0' }}>{hideContactsDescription(work.description)}</p>
                        <strong>{currency(work.price)}</strong>
                    </div>
                </div>
            </Link>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginTop: '20px',
                    pointerEvents: 'none',
                    opacity: .4
                }}
            >

                <LWCLiBtn
                    icon={<IoTrashBinOutline />}
                    txt={'Excluir'}
                />
                <LWCLiBtn
                    icon={<IoCreateOutline />}
                    txt={'Editar'}
                />
            </div>
        </li>
    )
}

const LWCLiBtn = ({ icon, txt }: { icon: any, txt: any }) =>
    <button className='center' style={{ marginLeft: '20px' }}>
        <div className='center' style={{ marginRight: '4px' }}>
            {icon}
        </div>
        {txt}
    </button>

export default ListWorksContainer
import { Link } from "react-router-dom"
import { getUser } from "../../../../../constants/localStorage/User"

function OrdersListEmpty() {
    return (
        <div style={{

            backgroundColor: 'white',
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-evenly',
            alignItems: 'center'
        }}>
            <div style={{ paddingTop: '70px' }}>
                <img
                    style={{
                        width: '260px',
                        height: '260px'
                    }}
                    alt="Lista com uma flecha"
                    src={require('../../../../../assets/emptyorders.png')}
                />
            </div>
            <div className="orders-list-empty-w">
                <strong style={{ fontSize: '20px', opacity: .9, }}>Contrate pela primeira vez</strong>
                <p style={{ opacity: '.7', textAlign: 'justify' }}>Quando você contrata um serviço pela primeira vez, ele aparece aqui.</p>
                <p style={{ opacity: '.7', textAlign: 'justify' }}>Quando você é contratado, seus clientes também aparecem aqui.</p>
                <Link to={'/search?search='}>
                    <div
                        className="center"
                        style={{
                            width: '100%',
                            height: '50px',
                            borderRadius: '9px',
                            backgroundColor: 'var(--green)',
                            color: 'white',
                            fontSize: '16px'

                        }}
                    >
                        <strong>Explore serviços</strong>
                    </div>
                </Link>
                {Boolean(getUser()) && <p style={{ opacity: '.7' }}>Não tem conta? <Link to={'/signin'}><strong>Crie uma agora.</strong></Link></p>}
            </div>
        </div>
    )
}

export default OrdersListEmpty
import { IoAdd } from "react-icons/io5"
import currency from "../../../../functions/currency"
import Modal from "../../../Modal"
import { useContext, useState } from "react"
import WcFirstModal from "./WcFirstModal"
import CreateWorkContext from "../context"
import { subwork_ } from "../../DetailsContainer/interface"

interface visibleWithId {
    visible: boolean,
    subwork?: subwork_
}

function WcSubworks() {
    const { subworks } = useContext(CreateWorkContext);
    const [visible, setVisible] = useState<visibleWithId>({ visible: false });
    const [increaseId, setIncreaseId] = useState<number>(1);

    const handleX = (sw?: visibleWithId['subwork']) => {
        sw
            ? setVisible({
                visible: true,
                subwork: sw
            })
            : setVisible({ visible: true });
    }
    return (
        <div className='wc-subworks'>
            <Modal
                visible={visible.visible}
                setVisible={() => setVisible({ visible: false })}
                children={
                    <WcFirstModal
                        increaseId={increaseId}
                        setIncreaseId={setIncreaseId}
                        setVisible={() => setVisible({ visible: false })}
                        sw={visible.subwork}
                    />
                }
            />
            <label
                style={{
                    marginTop: '18px'
                }}
                className='Label'
            >
                <p>Adicione mais serviços</p>
                <div>
                    <button
                        type='button'
                        onClick={() => handleX()}
                        className='wc-add-subworks center'
                    >
                        <IoAdd size={25} />
                    </button>
                </div>
                <ul className='wc-subworks-ul'>
                    {
                        subworks.map((subwork, idx) =>
                            <li
                                key={idx}
                            >
                                <button
                                    type='button'
                                    onClick={() => handleX(subwork)}
                                    className='wc-subworks-li card156'
                                >
                                    <strong>{subwork.title.slice(0, 30)}</strong>
                                    <p>{currency(subwork.price)}</p>
                                </button>
                            </li>

                        )
                    }
                </ul>
            </label>
        </div >
    )
}

export default WcSubworks;
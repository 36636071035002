import { useEffect, useState } from 'react'
import avatarStorage from '../../../constants/firebase/avatarStorage'
import AvatarPlaceholder from '../AvatarPlaceholder'

interface IAvatar {
    uid: any,
    size?: number,
    handleOnAvatarError?: () => void,
    borderRadius?: string,
    placeholder?: 'transparent'
}
function Avatar({ uid, size = 40, handleOnAvatarError, borderRadius = '100%', placeholder }: IAvatar) {

    const [avatar, setAvatar] = useState<string>('')

    const handleAvatar = () => {
        if (uid)
            setAvatar(avatarStorage(uid))
    }

    useEffect(() => {
        handleAvatar()
    }, [uid]);

    return (
        <span
            style={{
                position: 'relative',
                width: '100%',
                height: '100%'
            }}
            className='center'
        >
            {
                avatar &&
                <img
                    style={{
                        zIndex: 1,
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        top: 0,
                        left: 0,
                        borderRadius
                    }}
                    src={avatar.toString()}
                    alt='Imagem do usuário'
                    onError={() => {
                        handleOnAvatarError && handleOnAvatarError()
                        setAvatar('')
                    }}
                />
            }
            <AvatarPlaceholder size={size} backgroundColor={placeholder} borderRadius={borderRadius} />
        </span>
    )
}

export default Avatar
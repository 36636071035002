import { IoCall } from 'react-icons/io5';
import Input from '../../../Form/Inputs/nested/Input';
import { TextArea } from '../../../Form/Inputs/nested/TextArea';
import Parting from './Parting';
import MaxLengthDesc from '../../../MaxLengthDesc';
import { useContext } from 'react';
import CreateWorkContext from '../context';
import WcSubworks from './WcSubworks';
import min from '../../../../languages/pt-BR/minPrice.json'
import WcTaxs from './WcTaxs';

const WcFrist = () => {
    const {
        title, setTitle,
        description, setDescription,
        phone, setPhone,
        price, setPrice
    } =
        useContext(CreateWorkContext).work || {};

    return (
        <>
            <Input
                label='Serviço'
                placeholder='Ex: Assentamento de piso'
                value={title}
                onChange={setTitle}
                required
            />
            <TextArea
                label='Descrição'
                placeholder='Ex: Colocação de revestimentos no chão, como cerâmica, porcelanato ou laminado, utilizando argamassa e ferramentas adequadas para garantir um acabamento uniforme e duradouro.'
                value={description}
                onChange={setDescription}
            
            />
            <MaxLengthDesc
                countDesc={
                    description
                        ? description.length
                        : 0
                }
            />
            <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between' }}>
                <div style={{ width: '48%', position: 'relative' }}>
                    <Input
                        label='Valor (R$)'
                        placeholder={min}
                        value={Boolean(price) && price}
                        onChange={setPrice}
                        type='number'
                        min={50}
                        clear={true}
                        required
                    />
                </div>
                <WcTaxs price={price} />
            </div>

            <WcSubworks />

            <Parting
                text='Contato'
                icon={<IoCall size={20} color='#75757599' />}
            />
            <div className='wc-two column'>
                <Input
                    label='Telefone'
                    placeholder='Exemplo: (11) 9 9988-7766'
                    value={phone}
                    onChange={setPhone}
                    mask='phone'
                    required
                />
                <div style={{ width: '100%' }}>
                    <p style={{
                        fontSize: '.8em',
                        color: '#00000070'
                    }}
                    >
                        Ao informar seu número de contato você concorda em divulga-lo nos seus anúncios.
                    </p>
                </div>
            </div>
        </>
    )
}

export default WcFrist;
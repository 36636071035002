import './styles.css';
import { IoAddCircle, IoCalendar, IoPersonCircleOutline, IoReorderFourOutline } from 'react-icons/io5'
import { Link } from 'react-router-dom';
import scheduled_services from '../../../../../languages/pt-BR/scheduled_services.json'

function HIconsFixed({ setSideBarIsVisible, uid, toOrders }: { setSideBarIsVisible:any, uid:any, toOrders:any }) {

  const makeVisible = () => {
    setSideBarIsVisible(true)
  }

  return (
    <div className="HIconsFixed">


      {uid
        ? <FirstFixIcon
          onClick={toOrders}
          text={scheduled_services}
          icon={<IoCalendar />}
        />
        :
        <Link to='/login'>
          <FirstFixIcon
            icon={<IoPersonCircleOutline />}
          />
        </Link>
      }
      <div className='HIFix-icons' onClick={makeVisible}>
        <IoReorderFourOutline />
      </div>
      <Link to={window.localStorage.getItem('serss-user') ? '/work/create' : '/login'}>
        < div className='HIFix-add HIFix-icons'>
          <IoAddCircle />
        </div>
      </Link >

    </div >
  );
}

const FirstFixIcon = ({ onClick, text, icon }: { onClick?: () => void, text?: string, icon: JSX.Element }) => (
  <div className='HIFix-location'>
    <button className='HIFix-location-border' onClick={onClick && onClick}>
      <div className='HIFix-location-icon'>
        {icon}
      </div>
      <p className={text ? 'ellipsis' : ''} style={{ maxWidth: '100px', fontWeight: 500 }}>{
        text
          ? text
          : 'Cadastrar ou entrar'
      }</p>
    </button>
  </div>
)

export default HIconsFixed;

import { useContext, useState } from "react";
import currency from "../../../../../functions/currency";

import './styles.css';
import Mp from "../mercado_pago/Mp";
import DetailsContext from "../../context";
import { IoCheckmarkCircle } from "react-icons/io5";
import bannersStorage from "../../../../../constants/firebase/bannersStorage";
import api from "../../../../../config/connection/axios";
import headers from "../../../../../constants/localStorage/headers";
import Spinner from "../../../../Spinner";
import hideContactsDescription from "../../../../../functions/hideContactsDescription.simple";
import { MIN_PAYMENT } from '../../../../../constants/priceTag';
import { getUser } from "../../../../../constants/localStorage/User";
import { useNavigate } from "react-router-dom";

function SubworkDetails() {
  console.log(MIN_PAYMENT);
  const toDay = new Date().toISOString().split("T")[0];
  const [date, setDate] = useState(toDay);
  const [spinner, setSpinner] = useState<boolean>(false);
  const [x, setX] = useState<boolean>(true);
  const { selected } = useContext(DetailsContext);

  const { user_uid, id } = useContext(DetailsContext).work || {};

  const open = (link: string) => window.open(link, '_self');
  const navigate = useNavigate();
  const CreateWorkSubmit = () => {
    const hasPayment = window.sessionStorage.getItem(`${id}/${selected.id}`);
    if (hasPayment) return open(hasPayment);
    if (selected.price < MIN_PAYMENT) return;

    console.log(getUser().uid);

    if (!getUser().uid) return navigate('/signin');

    setSpinner(true);

    const body = {
      //"customer_cpf": "06088248521",
      transaction_amount: selected.price,
      provider_professional_uid: user_uid,
      execution_date: date,
      original_subwork_title: selected.title
    }

    api.post(
      '/pix/generate/payment',
      body,
      { headers: headers() }
    )
      .then(r => {
        const result = r.data;
        window.sessionStorage.setItem(
          `${id}/${selected.id}`,
          result.linkBuyMercadoPago
        );
        setSpinner(false);
        open(result.linkBuyMercadoPago);
      })
      .catch(() => setSpinner(true))
  }

  return (
    <div className='subwork-details'>
      <div
        className='subwork-details-img-wrap center'
      >
        {(id && x) && <img
          className='subwork-details-img'
          src={bannersStorage.cover(user_uid, id)}
          alt='ilustração do serviço'
          onError={() => setX(false)}
        />}
      </div>
      <div
        style={{
          padding: '30px 5%'
        }}
      >
        <div>
          <h2 style={{ margin: '0 0 6px 0' }}>{selected.title}</h2>
          <div style={{ maxWidth: '100%', marginBottom: '15px' }}>
            <p
              className="d-subwork-description"
            >
              {hideContactsDescription(selected.description)}
            </p>
          </div>
        </div>
        <div style={{ padding: '10px 0 40px 0' }}>
          <h4 style={{ margin: '0 0 10px 0' }}>Para quando você precisa?</h4>
          <div style={{ display: 'flex' }}>
            <input
              className='subwork-d-date'
              id='date'
              type='date'
              min={date}
              value={date}
              onChange={e => setDate(e.target.value)}
            />
            <div
              className="center"
              style={{
                marginLeft: '10px',
                opacity: (date !== toDay) ? 1 : 0
              }}
            >
              <IoCheckmarkCircle
                size={28}
                color={'var(--green)'}
              />
            </div>
          </div>
        </div>
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end'
            }}
          >
            <div
              className='subwork-price-btn-wrap'
            >
              <strong
                style={{
                  fontSize: '20px',
                  paddingRight: '20px'
                }}
              >
                {currency(selected.price)}
              </strong>
              <button
                className="subwork-d-button"
                onClick={CreateWorkSubmit}
                style={{
                  pointerEvents: (selected.price < MIN_PAYMENT || spinner) ? 'none' : 'auto',
                  opacity: (selected.price < MIN_PAYMENT || spinner) ? .6 : 1,
                }}
              >
                {
                  spinner
                    ? <Spinner visible={spinner} />
                    : 'Contratar'
                }
              </button>
            </div>
          </div>
          <Mp />
        </div>
      </div>
    </div>
  )
}



export default SubworkDetails
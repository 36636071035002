import { useContext } from 'react';
import FormButton from '../../../Form/Inputs/nested/FormButton';
import WindowAlertContext from '../../../../context/windowAlert.context';
import shake from '../../../../languages/windowAlertForm.json';
import min from '../../../../languages/pt-BR/minPrice.json';
import CreateWorkContext from '../context';
import currency from '../../../../functions/currency';
import { PRICE } from '../../../../constants/priceTag';

interface WcFinishBtn_ {
    handleSubmit: any,
    spinner: any,
    isPro: boolean
}

function WcFinishBtn({ handleSubmit, spinner, isPro }: WcFinishBtn_) {
    const windowAlert = useContext(WindowAlertContext);
    const { title, description, price, phone, locationData } = useContext(CreateWorkContext).work || {};

    const handleOpaqueBtn = (e: Event) => {
        e.preventDefault();

        price < 50
            ? windowAlert?.confirm({
                shake: min
            })
            : windowAlert?.confirm({
                strong: "Campos obrigatórios",
                shake
            })
    }

    const boo = Boolean((
        title && description && price >= 50
        && phone && locationData &&
        locationData.city_id
    ));
    const style = boo
        ? {
            borderRadius: '8px',
            height: '48px',
            width: '90%'
        }
        : {
            borderRadius: '8px',
            height: '48px',
            width: '100%',
            border: '1px solid transparent',
            color: '#eee',
            opacity: .4
        }

    return (
        <div
            className='done-button center column'
        >
            <div style={{ width: '90%', display: 'flex', alignItems: 'center' }}>
                {!isPro && <div>
                    {/*<p 
                    
                    style={{
                        textDecoration: 'line-through',
                        margin: 0,
                        fontSize: '9px',
                        opacity:.5
                    }}
                    >
                        R$ 19,90
                    </p>*/}
                    <strong
                        style={{
                            fontSize: '20px',
                            paddingRight: '20px'

                        }}
                    >
                        {currency(PRICE)}
                    </strong>
                </div>}
                <div style={{ flex: 1, height: 'fit-content' }}>
                    <FormButton
                        onClick={boo ? handleSubmit : handleOpaqueBtn}
                        text='Anunciar'
                        spinner={spinner}
                        space={0}
                        style={style}
                    />
                </div>
            </div>

            {!isPro && <div
                className="mercad-pago-pgp"
            >
                <p style={{ fontSize: '12px' }}>Adquira uma vez e <strong>anuncie o quanto quiser!</strong></p>
            </div>}

        </div>

    )
}

export default WcFinishBtn;
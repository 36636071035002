import './styles.css'
function DownApp() {
    return (
        <div className='center' id='DownApp'>
            <div>
                <img className='down-tablet' src={require('../../../assets/down.png')} alt='Tablat da servicess' />
            </div>
            <div className='down-middle'>
                <h2>Baixe Nosso App</h2>
                <span>Os melhores profissionais na palma da mão!</span>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-around', marginTop: '60px' }}>
                    <a href='https://apps.apple.com/br/app/servicess-encontre-servi%C3%A7os/id1641960454'>
                        <img className='down-store' src={require('../../../assets/appstore.png')} alt='Apple Store' />
                    </a>
                    <a href='https://play.google.com/store/apps/details?id=servicess.app.mobile'>
                        <img className='down-store' src={require('../../../assets/googleplay.png')} alt='Apple Store' />
                    </a>
                </div>
            </div>
        </div>
    )
}

export default DownApp
import CategoriesSlider from '../../homePage/CategoriesSlider'
import Stripe from '../../homePage/Stripe'
import MiniBanners from '../../MiniBanners'
import SafeDiv from '../../SafeDiv'
import ProfessionsContainer from '../ProfessionsContainer'
import './styles.css'

import AboutUs from '../../rlx-components/AboutUs'
import HomeWorks from './nested/HomeWorks'
import CardsCase from '../../Cards/CardsCase'
import PopularCategories from '../../homePage/PopularCategories'
import PopularProfessionals from '../../homePage/PopularProfessionals'
import FindItOut from '../../homePage/FindItOut'
import DownApp from '../../homePage/DownApp'
import ScAlertHome from '../../ScAlerts/ScAlertHome'

function HomeContainer() {
    return (
        <SafeDiv center={true}>
            <div id='HomeContainer'>
                <ProfessionsContainer />
                <HomeTitle
                    className='mrg-0'
                    title='Mais pesquisados'
                    subtitle='Serviços mais pesquisados'
                />
                <CategoriesSlider />

                <PopularProfessionals>
                    <HomeTitle
                        title='Profissões'
                        subtitle='Os profissionais que você precisa'
                    />
                </PopularProfessionals>


                <HomeTitle
                    title='Mais Procurados'
                    subtitle='Confira os mais procurados'
                />

                {
                    window.innerWidth > 960
                        ? <HomeWorks box={2} />
                        : <CardsCase
                            text='Novidades'
                            box={2}
                        />
                }

                <HomeTitle
                    title='Junte-se à Comunidade'
                    subtitle='Quando a comunidade cresce, você também cresce'
                />
                <MiniBanners />
                <Stripe />


                <HomeTitle
                    title='Recomendados'
                    subtitle='Confira nossas recomendações'
                />
                {
                    window.innerWidth > 960
                        ? <HomeWorks box={5} />
                        : <CardsCase
                            text='Recomendados'
                            box={1}
                            content={4}
                        />
                }

                <HomeTitle
                    title='Tudo que você precisa'
                    subtitle='Tudo que você procura está aqui'
                />
                <FindItOut />

                <HomeTitle
                    title='Muito Mais'
                    subtitle='Confira esses destaques'
                />
                {
                    window.innerWidth > 960
                        ? <HomeWorks box={1} />
                        : <CardsCase
                            text='Destaques'
                        />
                }

                <PopularCategories>
                    <HomeTitle
                        title='Categorias'
                        subtitle='Categorias mais populares'
                    />
                </PopularCategories>

                <HomeTitle
                    title='Descubra'
                    subtitle='Descubra mais serviços'
                />
                {
                    window.innerWidth > 960
                        ? <HomeWorks box={3} />
                        : <CardsCase
                            text='Descubra'
                            box={2}
                            content={2}
                        />
                }

                <HomeTitle
                    title='Populares'
                    subtitle='Veja o que está bombando'
                />
                {
                    window.innerWidth > 960
                        ? <HomeWorks box={4} />
                        : <CardsCase
                            text='Populares'
                            box={1}
                            content={3}
                        />
                }
                {!navigator.userAgent.match(/expo/i) && <DownApp />}

                <ScAlertHome />

                <AboutUs />
            </div>
        </SafeDiv>
    )
}
interface Hometitle {
    title: string,
    subtitle?: string
    className?: string
}
const HomeTitle = ({ title, subtitle, className }: Hometitle) => {
    return (
        <div
            className={`${className} HomeTitle`}
        >
            <h2>
                {title}
            </h2>
            <p>{subtitle}</p>
        </div>
    );
}
export default HomeContainer;
import './styles.css';

//Components:
import SafeDiv from '../../SafeDiv';
import Card from '../../Cards/Card';
import Spinner from '../../Spinner';
import { useEffect, useState } from 'react';

import Location from '../../Locations/Location';
import api from '../../../config/connection/axios';
import { Link } from 'react-router-dom';
import { IoChevronDown, IoEllipsisVertical, IoList, IoOptions } from 'react-icons/io5';
import SearchTitles from '../../SearchTitles';
import work_ from '../../../interfaces/works.interface'

import AdsSearchRight_Wild from '../../googleAds/wildscreen/AdsSearchRight_Wild';
import AdsSearchSquareOneMobile from '../../googleAds/mobilescreen/AdsSearchSquareOneMobile';
import AdsSearchSquareTwoMobile from '../../googleAds/mobilescreen/AdsSearchSquareTwoMobile';
import SeeButton from '../../Buttons/SeeButton';

interface SearchContainer_ {
    search: string,
    switcher?: boolean,
    setSwitcher?: React.Dispatch<React.SetStateAction<boolean>>
}

type cards_ = Array<work_>;

interface resResponse_ {
    list: cards_,
    excluded: Array<string>
};


function SearchContainer({
    search = '',
    switcher,
    setSwitcher
}: SearchContainer_) {
    const [offset, setOffset] = useState<number>(0);

    const [cards, setCards] = useState<cards_>([]);
    const [excluded, setExcluded] = useState<Array<string>>([])


    const [locationIsVisible, setLocationIsVisible] = useState<boolean>(false);
    const [loc, setLoc] = useState<string>('');
    const [cityId, setCityId] = useState<string>('');

    const [spinner, setSpinner] = useState<boolean>(false);
    const [stop, setStop] = useState<boolean>(false);

    const [showLeft, setShowLeft] = useState<boolean>(false);

    const handleLocation = () => {
        const serssLocation = JSON.parse(window.localStorage.getItem('serss-location') || '{}')
        const city_id = JSON.stringify(serssLocation) === '{}'
            ? ''
            : serssLocation.city_id;

        setLoc(
            (Object.keys(serssLocation).length === 0)
                ? 'Todo o Brasil'
                : serssLocation.city_name + ', ' + serssLocation.state_name
        )
        setCityId(city_id)
        return city_id;
    }

    const handleLoadMore = () => {
        if (!stop) handlePremium()
    }

    const handlePremium = async () => {
        if (stop) return;

        setSpinner(true);

        await api.put(
            `/cards/list?search=${search}&location=${!cityId.length
                ? handleLocation()
                : cityId}&offset=${offset}`,
            { excluded }
        )
            .then(r => {
                const result: resResponse_ = r.data;

                const newList = cards.concat(result.list)

                setCards(newList);
                setExcluded(result.excluded);

                // Para se não tiver mais anúncios premium e reiniciar o offset: 
                if (result.list.length < 21) setStop(true);
                else setOffset(offset + 1);

                return setSpinner(false);
            })
    }


    useEffect(() => {
        handlePremium()
    }, [])

    const makeLocVisible = (e: any) => {
        e.preventDefault()
        setLocationIsVisible(true)
    }


    const handleCleanFilter = () => {
        setShowLeft(false)
        window.localStorage.setItem('serss-location', '{}')
    }

    return (
        <SafeDiv>
            <Location
                locationIsVisible={locationIsVisible}
                setLocationIsVisible={setLocationIsVisible}
                switcher={switcher}
                setSwitcher={setSwitcher}
            />
            <div id='SearchContainer' style={{ overflow: showLeft ? 'hidden' : '' }}>
                <div className={'SerC-left'}>
                    <form className='filter-border'>
                        <ul style={{ height: '100%' }}>

                            <li style={{ height: '30%', paddingTop: '32px' }}>
                                <strong>Localização</strong>
                                <div
                                    style={{
                                        width: '80%',
                                    }}

                                >
                                    <button
                                        style={{
                                            marginTop: '20px',
                                            height: '48px',
                                            borderRadius: '12px',
                                            fontWeight: 'bold',
                                            color: 'var(--dark)',
                                            boxShadow: '#DEE6EB -2px 2px 6px',
                                            width: '100%',
                                            border: '1px solid var(--border)'
                                        }}
                                        onClick={makeLocVisible}
                                    >
                                        {loc}
                                    </button>
                                </div>
                            </li>
                            <li style={{ height: '8%' }} className='SerC-to-hide'>
                                <CleanFilter onClick={handleCleanFilter} />
                            </li>
                        </ul>
                    </form>
                </div>
                <div className='SerC-mid'>
                    <div style={{ width: '100%' }} className='center column'>
                        <SearchFilter setShowLeft={setShowLeft} makeLocVisible={makeLocVisible} />
                    </div>

                    {
                        spinner &&
                        <div className='Ser-spinner-wrap center' >
                            <Spinner
                                visible={true}
                                color={'var(--dark)'}
                            />
                        </div>
                    }

                    <SercMidGridPremium
                        loc={loc}
                        search={search}
                        offset={offset}
                        pWorks={cards}
                    />

                    {
                        (!cards.length && !spinner) &&
                        <>
                            <SearchTitles
                                title={`Nenhum resultado encontrado ${search ? `para "${search}"` : ''}`}
                                subtitle={`Seja você o primeiro ${search ? `"${search}"` : ''} da sua cidade!`}
                            />
                            <div style={{ width: '400px', backgroundColor: 'white' }}>
                                <SeeButton text='Anunciar meu serviço' to={window.localStorage.getItem('serss-user') && window.localStorage.getItem('serss-user') !== '{}' ? '/work/create' : '/signin'} />
                            </div>
                        </>
                    }

                    {(!stop) &&
                        <div className='center' style={{ width: '100%', height: 'fit-content', marginTop: '50px' }}>
                            <button
                                onClick={handleLoadMore}
                                className='center'
                                style={{
                                    backgroundColor: '#222D36',
                                    padding: '20px 60px',
                                    borderRadius: '100px',
                                    color: 'white',
                                    fontWeight: 'bolder',
                                    fontSize: '16px',
                                    display: spinner ? 'none' : 'flex'
                                }}
                            >
                                Mais
                                <span className='center' style={{ margin: '0 0 0 4px' }}>
                                    <IoChevronDown size={16} />
                                </span>
                            </button>
                        </div>
                    }

                </div>

                <div className='SerC-right'>
                    <AdsSearchRight_Wild />
                </div>

            </div>
        </SafeDiv>
    )
}

const SercMidGrid = ({ composed, stop, search }: { composed: cards_, stop: boolean, search: string }) => {
    return (
        <>
            <SearchTitles
                title={
                    search
                        ? search.charAt(0).toUpperCase() + search.slice(1)
                        : 'Mais opções'
                }
                subtitle={`Confira mais opções ${search ? `para "${search}"` : ''}`}
            />
            <div className='SerC-mid-grid'>
                {
                    <>
                        {
                            composed.map((work: any, idx: any) =>
                                <>
                                    <Card
                                        className='InSearch'
                                        work={work}
                                        key={idx}
                                    />
                                    {
                                        (idx === 3 || idx === 7) &&
                                        <PublicidadeQuadrada>
                                            <AdsSearchSquareTwoMobile />
                                        </PublicidadeQuadrada>
                                    }
                                </>
                            )
                        }
                    </>
                }
            </div >
        </>
    )
}

const SercMidGridPremium = ({ pWorks, offset, search, loc }: { pWorks: Array<work_>, offset: number, search: string, loc: string }) => {
    return (
        <>
            <div className='SerC-mid-grid'>
                {
                    <>
                        {
                            pWorks.map((work: any, idx: any) =>
                                <>
                                    <Card
                                        className='InSearch'
                                        work={work}
                                        key={idx}
                                    />
                                    {
                                        (idx === 3 || idx === 7) &&
                                        <PublicidadeQuadrada>
                                            <AdsSearchSquareOneMobile />
                                        </PublicidadeQuadrada>
                                    }
                                </>

                            )
                        }
                    </>
                }
            </div >
        </>
    )
}

const CleanFilter = ({ onClick }: { onClick: any }) => (
    <p
        style={{
            margin: 0,
            textDecoration: 'underline',
            color: '#757575',
            fontSize: '.9em'
        }}
        onClick={onClick}
    >
        <Link to='/search?search='>
            Limpar filtros
        </Link>
    </p>
)


const SearchFilter = ({ setShowLeft, makeLocVisible }: { setShowLeft: any, makeLocVisible: any }) => (
    <div className='SerC-filters-wrapper'>
        <span
            className='center'
            style={{
                position: 'absolute',
                top: 0,
                left: '48%',
                height: '100%',
                color: '#c4c4c4',

            }}>
            <IoEllipsisVertical size={24} />
        </span>
        <div className='SerC-filters'>

            <div className='center'>
                <Link to='/categories'>
                    <div className='center' style={{ paddingLeft: '30px' }}>
                        <span style={{ marginRight: '8px' }}>
                            <IoList size={23} />
                        </span>
                        Categorias
                    </div>
                </Link>
            </div>


            <div
                className='center' style={{ paddingRight: '40px' }}
                onClick={makeLocVisible}
            >
                <span style={{ marginRight: '8px' }}>
                    <IoOptions size={23} />
                </span>
                Localização
            </div>
        </div>
    </div>
)

const Publicidade = () => (
    <div
        style={{
            color: '#8994a9',
            fontFamily: "Nunito Sans, Helvetica Neue, Helvetica, Arial, sans-serif", margin: '12px 10px 10px 10px'
        }}
    >
        publicidade
    </div>
)

const PublicidadeQuadrada = ({ children }: { children: JSX.Element }) => (
    <div className='hide-mobile' style={{ flexDirection: 'column' }}>
        <Publicidade />
        <div style={{ width: '100%', height: window.innerWidth }}>
            <div style={{ width: '96%', height: '96%' }}>
                {children}
            </div>
        </div>
    </div>
)

export default SearchContainer;
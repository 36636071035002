import { IoAddCircleOutline, IoCalendarOutline, IoCreateOutline, IoListOutline, IoLocationOutline, IoNewspaperOutline } from 'react-icons/io5'
import { Link } from 'react-router-dom';
import '../styles.css'
import { getUser } from '../../../constants/localStorage/User';
import Avatar from '../../imagesComponents/Avatar';
import ShLi from './ShLi';
import { useState } from 'react';
import ShLiMainCat from './ShLiMainCat';
import scheduled_services from '../../../languages/pt-BR/scheduled_services.json'

interface SideBarContent_ {
    Button?: JSX.Element,

    makeLocVisible?: (b: boolean) => void,
    type?: 'default' | 'categories' | 'works'
}

function SideBarContent({ Button, makeLocVisible, type }: SideBarContent_) {
    const User = getUser();
    const [switcher, setSwitcher] = useState<SideBarContent_['type']>(type || 'default');

    return (
        <div style={{ backgroundColor: '#fff' }}>
            {Button && Button}
            {switcher === 'default' &&
                <div>
                    <ul className='SB-list'>
                        <li className='SB-list-li-user'>
                            <div className='SB-list-li-user-container'>
                                <div className='SB-list-li-user-info'>
                                    <div style={{ position: 'relative', width: '60px', height: '60px' }}>
                                        <Avatar
                                            uid={User.uid}
                                            size={16}
                                            borderRadius='12px'
                                        />
                                    </div>
                                    <span className='SB-list-li-user-text'>
                                        {
                                            User.name
                                                ? (
                                                    <>
                                                        <h5>{User.profession}</h5>
                                                        {User.name}
                                                    </>

                                                )
                                                : (
                                                    <>
                                                        Faça login
                                                        <br />
                                                        ou cadastre-se
                                                    </>
                                                )
                                        }
                                    </span>
                                </div>
                                <div className='SB-list-li-user-buttons'>
                                    {
                                        User.name
                                            ? (

                                                <Link
                                                    to='/work/create'
                                                    className='SB-list-li-user-btn-login SB-btn'
                                                    style={{ width: '100%' }}
                                                >
                                                    Anunciar
                                                </Link>

                                            )
                                            : (
                                                <>
                                                    <Link
                                                        to='/login'
                                                        className='SB-list-li-user-btn-login SB-btn'

                                                    >
                                                        Entrar
                                                    </Link>
                                                    <Link
                                                        to='/signin'
                                                        className='SB-list-li-user-btn-signin SB-btn'

                                                    >
                                                        Cadastrar
                                                    </Link>
                                                </>
                                            )
                                    }
                                </div>
                            </div>
                        </li>
                        <div>
                            <div>
                                <Link to={User.uid ? '/orders' : '/login'}>
                                    <ShLi text={scheduled_services} borderless>
                                        <IoCalendarOutline />
                                    </ShLi>

                                    <div>
                                        <ShLi borderless text='Minhas vendas' />
                                        <ShLi borderless text='Minhas compras' />
                                    </div>
                                    <div className='SB-li' style={{ height: 0 }} />
                                </Link>
                            </div>
                            <Link to={User.uid ? '/work/create' : '/login'}>
                                <ShLi text='Anunciar meu serviço'>
                                    <IoAddCircleOutline />
                                </ShLi>
                            </Link>
                            {
                                <Link to={User.uid ? '/cards/belongs' : '/login'}>
                                    <ShLi text='Meus anúncios'>
                                        <IoNewspaperOutline />
                                    </ShLi>
                                </Link>

                            }
                            <Link to={User.uid ? '/registration' : '/login'}>
                                <ShLi text='Dados pessoais'>
                                    <IoCreateOutline />
                                </ShLi>
                            </Link>
                            {makeLocVisible && <ShLi text='Localização' onClick={makeLocVisible}>
                                <IoLocationOutline />
                            </ShLi>}
                            <ShLi text='Categorias' onClick={() => setSwitcher('categories')}>
                                <IoListOutline />
                            </ShLi>

                        </div>
                    </ul>
                </div>
            }
            {
                switcher === 'categories' &&
                <ShLiMainCat setSwitcher={setSwitcher} />
            }
        </div>
    )
}

export default SideBarContent